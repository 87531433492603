// boniw start
import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize, ExcelExport } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import { API_ROOT } from "../../../config";
import { Header, PageLoading } from "../../../components";

const TransactionReportSummary = () => {

    const navigate = useNavigate();

    const [pageLoading, setPageLoading] = useState(true);
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [transactionList, setTransactionList] = useState([]);
    const gridRef = useRef(null);
  
    const formatDate = (inputDate) => {
      const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
      ];
    
      const days = [
        "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
      ];
    
      const date = new Date(inputDate);
      
      const dayOfWeek = days[date.getUTCDay()];
      const day = date.getUTCDate();
      const month = months[date.getUTCMonth()];
      const year = date.getUTCFullYear();
      
      const hours = date.getUTCHours();
      const minutes = date.getUTCMinutes();
      const seconds = date.getUTCSeconds();
      
      return `${dayOfWeek}, ${day} ${month} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }
  
    const fetchData = async () => {
      await axios.get(API_ROOT + `/admin/masterdata-management/transaction-report?from=${fromDate}&to=${toDate}`, {
        headers: {
          Authorization: getCookie("admin_auth"),
        },
      }).then((response) => {
        const data = response.data.data;
  
        setTransactionList(() => data.map((item, index) => (
          {
            No: index+1,
            Uuid: item.uuid,
            PlatformName: item.platform_name || "-",
            Name: item.name,
            Balance: item.balance,
            JumlahTTE: parseInt(item.jumlah_tte),
          }
        )));
  
        setPageLoading(false);
      }).catch((error) => {
        if (error.response) {
          if (error.response.status == 401) {
            navigate("/login");
  
            return;
          }
        }
        toast.error("Failed to get data");
      });
    }
  
    useEffect(() => {
      fetchData();
    }, []);
  
    const queryCellInfo = (args) => {
      args.cell?.classList.add("e-cell");
    }
  
    const dataBound = () => {
      if (gridRef.current) {
        gridRef.current.autoFitColumns(["Name", "Username", "Email", "EmailVerified", "PhoneNumber", "PhoneNumberVerified", "Balance", "CreatedAt"]);
      }
    }
  
    const toolbarClick = (args) => {
      if (gridRef.current && args.item.id.includes("excelexport")) {
        const excelExportProperties = {
          fileName: "Paperless - Transaction Report Summary.xlsx"
        };
        gridRef.current.excelExport(excelExportProperties);
      }
    }
  
    return pageLoading ? (<PageLoading />) : (
      <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
        <Header title="Transaction Report Summary" />
        <div className="overflow-x-auto">
          <div className="w-fit cursor-pointer">
            <div className="flex mb-4 gap-4 items-end">
                <div className="">
                    <label htmlFor="" className="block text-sm mb-1">Dari</label>
                    <input type="date" onChange={e => setFromDate(e.target.value)}
                     name="" id="" className="block border border-gray-300 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
                <div className="">
                    <label htmlFor="" className="block text-sm mb-1">Sampai</label>
                    <input type="date" onChange={e => setToDate(e.target.value)}
                     name="" id="" className="block border border-gray-300 rounded-lg p-2 w-full focus:outline-none focus:ring-2 focus:ring-blue-500" />
                </div>
                <button className={"text-white w-44 py-2 px-4 rounded-md text-sm bg-green-500 hover:bg-green-600"} onClick={fetchData}>Filter</button>
            </div>
            <GridComponent
              dataSource={transactionList}
              width="auto"
              allowPaging
              allowSorting
              allowTextWrap={true}
              pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
              textWrapSettings={{ wrapMode: 'Content' }}
              toolbar={["Search", "ExcelExport"]}
              queryCellInfo={queryCellInfo}
              allowExcelExport={true}
              toolbarClick={toolbarClick}
              dataBound={dataBound}
              ref={gridRef}
            >
              <ColumnsDirective>
                <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} type="Number" />
                <ColumnDirective field="Uuid" headerText="Uuid" isPrimaryKey={true} visible={false} />
                <ColumnDirective field="PlatformName" headerText="Platform Name" />
                <ColumnDirective field="Name" headerText="Name" />
                <ColumnDirective field="Balance" headerText="Balance" textAlign="Center" />
                <ColumnDirective field="JumlahTTE" headerText="Jumlah TTE" />
              </ColumnsDirective>
              <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
            </GridComponent>
          </div>
        </div>
      </div>
    );
}

export default TransactionReportSummary;
// boniw end