import axios from "axios";
import { getCookie } from "cookies-next";
import { FiSettings } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Routes, Route } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

import { API_ROOT } from "../config";
import {
  UserList,
  BalanceTransaction,
  AuditTrail,
  IntegratedPlatform,
  IntegratedPlatformDetail,
  RecapPascabayar,
  RecapPascabayarDetail,
  PascabayarPayment,
  PascabayarPaymentDetail,
  PascabayarTransaction,
  TransactionConfig,
  PaymentMethods,
  TransactionReportSummary
} from "../pages";

import {
  Navbar,
  Footer,
  Sidebar,
  ThemeSettings,
  LoadingScreen,
} from "../components";

import { useStateContext } from "../contexts/ContextProvider";

import "../App.css";
import "react-toastify/dist/ReactToastify.css";

const Main = () => {

  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);
  const [profileData, setProfileData] = useState(null);

  const {
    setCurrentColor,
    setCurrentMode,
    currentMode,
    activeMenu,
    currentColor,
    themeSettings,
    setThemeSettings,
  } = useStateContext();

  const tokenCheck = async () => {
    await axios.get(API_ROOT + "/admin/auth/token-check", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      setProfileData(response.data.data);
      setIsLoading(false);
    }).catch((error) => {
      navigate("/login");
    });
  }

  useEffect(() => {
    const currentThemeColor = localStorage.getItem("colorMode");
    const currentThemeMode = localStorage.getItem("themeMode");

    if (currentThemeColor && currentThemeMode) {
      setCurrentColor(currentThemeColor);
      setCurrentMode(currentThemeMode);
    }

    tokenCheck();
  }, []);

  return isLoading ? (<LoadingScreen /> ) : ( 
    <div className={currentMode === "Dark" ? "dark" : ""}>
      <ToastContainer
        hideProgressBar={true}
        autoClose={2000}
        theme="colored"
      />
      <div className="flex relative dark:bg-main-dark-bg">
        <div className="fixed right-4 bottom-4" style={{ zIndex: "1000" }}>
          <TooltipComponent content="Settings" position="Top">
            <button
              type="button"
              onClick={() => setThemeSettings(true)}
              style={{ background: currentColor, borderRadius: "50%" }}
              className="text-3xl text-white p-3 hover:drop-shadow-xl hover:bg-light-gray"
            >
              <FiSettings />
            </button>
          </TooltipComponent>
        </div>
        {activeMenu ? (
          <div className="w-72 fixed sidebar dark:bg-secondary-dark-bg bg-white ">
            <Sidebar />
          </div>
        ) : (
          <div className="w-0 dark:bg-secondary-dark-bg">
            <Sidebar />
          </div>
        )}
        <div
          className={
            activeMenu
              ? "dark:bg-main-dark-bg bg-main-bg min-h-screen md:ml-72 w-full md:w-[calc(100vw-288px)] relative "
              : "bg-main-bg dark:bg-main-dark-bg w-full min-h-screen flex-2 relative "
          }
        >
          <div className="fixed md:static bg-main-bg dark:bg-main-dark-bg navbar w-full ">
            <Navbar profileData={profileData} />
          </div>
          <div>
            {themeSettings && <ThemeSettings />}
            <Routes>
              {/* USER */}
              <Route path="/user/user-list" element={<UserList />} />
              <Route path="/user/balance-transaction" element={<BalanceTransaction />} />
              <Route path="/user/audit-trail" element={<AuditTrail />} />
              <Route path="/user/integrated-platform" element={<IntegratedPlatform />} />
              <Route path="/user/integrated-platform/:uuid" element={<IntegratedPlatformDetail />} />
              <Route path="/user/recap-pascabayar" element={<RecapPascabayar />} />
              <Route path="/user/recap-pascabayar/:uuid" element={<RecapPascabayarDetail />} />
              <Route path="/user/pascabayar-payment" element={<PascabayarPayment />} />
              <Route path="/user/pascabayar-payment/:uuid" element={<PascabayarPaymentDetail />} />
              <Route path="/user/pascabayar-transaction" element={<PascabayarTransaction />} />

              {/* MasterData */}
              <Route path="/masterdata/transaction-config" element={<TransactionConfig />} />
              <Route path="/masterdata/payment-methods" element={<PaymentMethods />} />
                {/* boniw start */}
                <Route path="/masterdata/transaction-report-summary" element={<TransactionReportSummary />} />
              {/* boniw end */}
            </Routes>
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Main;
