import axios from "axios";
import { toast } from "react-toastify";
import { getCookie } from "cookies-next";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { GridComponent, Inject, ColumnsDirective, ColumnDirective, Search, Page, Sort, Toolbar, Resize, ExcelExport } from "@syncfusion/ej2-react-grids";

import "../../table.css";
import { API_ROOT } from "../../../config";
import { Header, PageLoading } from "../../../components";

const UserList = () => {

  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(true);
  const [userList, setUserList] = useState([]);
  const gridRef = useRef(null);

  const formatDate = (inputDate) => {
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
  
    const days = [
      "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"
    ];
  
    const date = new Date(inputDate);
    
    const dayOfWeek = days[date.getUTCDay()];
    const day = date.getUTCDate();
    const month = months[date.getUTCMonth()];
    const year = date.getUTCFullYear();
    
    const hours = date.getUTCHours();
    const minutes = date.getUTCMinutes();
    const seconds = date.getUTCSeconds();
    
    return `${dayOfWeek}, ${day} ${month} ${year} ${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  }

  const fetchData = async () => {
    await axios.get(API_ROOT + "/admin/user/list?offset=1&limit=all", {
      headers: {
        Authorization: getCookie("admin_auth"),
      },
    }).then((response) => {
      const data = response.data.data;
      console.log(data[0].users_balance?.balance)

      setUserList(() => data.map((item, index) => (
        {
          No: index+1,
          Uuid: item.uuid,
          Name: item.name,
          Username: item.username,
           //boniw start
           Balance: item.users_balance.balance,
           //boniw end
          Email: item.email,
          EmailVerified: item.email_verified,
          PhoneNumber: item.phone_number,
          PhoneNumberVerified: item.phone_number_verified,
          CreatedAt: formatDate(item.created_at),
        }
      )));

      setPageLoading(false);
    }).catch((error) => {
      if (error.response) {
        if (error.response.status == 401) {
          navigate("/login");

          return;
        }
      }
      toast.error("Failed to get data");
    });
  }

  useEffect(() => {
    fetchData();
  }, []);

  const queryCellInfo = (args) => {
    args.cell?.classList.add("e-cell");
  }

  // const dataBound = () => {
  //   if (gridRef.current) {
  //     gridRef.current.autoFitColumns(["Name", "Username", "Email", "EmailVerified", "PhoneNumber", "PhoneNumberVerified", "CreatedAt"]);
  //   }
  // }
  //boniw
  const dataBound = () => {
    if (gridRef.current) {
      console.log("Current data:", gridRef.current.dataSource);
      gridRef.current.autoFitColumns(["Name", "Username", "Balance","Email", "EmailVerified", "PhoneNumber", "PhoneNumberVerified", "CreatedAt"]);
    }
  }

  const toolbarClick = (args) => {
    if (gridRef.current && args.item.id.includes("excelexport")) {
      const excelExportProperties = {
        fileName: "Paperless - Digitalisasi Untuk Negeri User List.xlsx"
      };
      gridRef.current.excelExport(excelExportProperties);
    }
  }

  return pageLoading ? (<PageLoading />) : (
    <div className="m-2 md:m-10 mt-24 px-2 py-10 md:p-10 bg-white rounded-3xl">
      <Header title="User List Data" />
      <div className="overflow-x-auto">
        <div className="w-fit cursor-pointer">
          <GridComponent
            dataSource={userList}
            width="auto"
            allowPaging
            allowSorting
            allowTextWrap={true}
            pageSettings={{ pageCount: 5, pageSizes: ["All", "10", "25", "50"] }}
            textWrapSettings={{ wrapMode: 'Content' }}
            toolbar={["Search", "ExcelExport"]}
            queryCellInfo={queryCellInfo}
            allowExcelExport={true}
            toolbarClick={toolbarClick}
            dataBound={dataBound}
            ref={gridRef}
          >
            <ColumnsDirective>
              <ColumnDirective field="No" headerText="No" textAlign="Center" width={50} type="Number" />
              <ColumnDirective field="Uuid" headerText="Uuid" isPrimaryKey={true} visible={false} />
              <ColumnDirective field="Name" headerText="Name" />
              <ColumnDirective field="Username" headerText="Username" />
               {/* asep start */}
               <ColumnDirective field="Balance" headerText="Balance" textAlign="Center"/>
              {/* asep end */}
              <ColumnDirective field="Email" headerText="Email"  textAlign="Center"/>
              <ColumnDirective field="EmailVerified" headerText="Email Verified" textAlign="Center" />
              <ColumnDirective field="PhoneNumber" headerText="Phone Number" />
              <ColumnDirective field="PhoneNumberVerified" headerText="Phone Number Verified" textAlign="Center" />
              <ColumnDirective field="CreatedAt" headerText="Created At" />
            </ColumnsDirective>
            <Inject services={[Search, Toolbar, Page, Sort, Resize, ExcelExport]} />
          </GridComponent>
        </div>
      </div>
    </div>
  );
};
export default UserList;